<template>
    <div class="content" style="display:flex;justify-content:center">
        <el-card style="width: 400px" header="修改密码" body-style="text-align:center">
            <el-form :model="password" size="mini" label-width="80px" ref="password" :rules="rules">
                <el-form-item label="原始密码" prop="one">
                    <el-input v-model="password.one" clearable show-password placeholder="请输入原始密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="two">
                    <el-input v-model="password.two" clearable show-password placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="three">
                    <el-input v-model="password.three" clearable show-password placeholder="请再次输入密码"></el-input>
                </el-form-item>
                <el-button type="primary" size="mini" @click="submitPwd" :loading="loading">确 定</el-button>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import axiosUtils from "../../utils/axiosUtils";

    export default {
        name: "setting",
        data() {
            let checkNewPassword = (rule, value, callback) => {
                if (!this.password.two) {
                    callback(new Error('新密码不能为空'));
                } else if (this.password.two !== this.password.three) {
                    callback(new Error('两次新密码不一致'));
                } else {
                    callback();
                }
            }
            let checkNewPassword2 = (rule, value, callback) => {
                if (!this.password.three) {
                    callback(new Error('确认密码不能为空'));
                } else if (this.password.two !== this.password.three) {
                    callback(new Error('两次新密码不一致'));
                } else {
                    callback();
                }
            }
            return {
                loading: false,
                password: {
                    one: '',
                    two: '',
                    three: '',
                },
                rules: {
                    one: [
                        {required: true, message: '初始密码不能为空', trigger: 'blur'},
                    ],
                    two: [
                        {required: true, trigger: 'blur', validator: checkNewPassword,},
                    ],
                    three: [
                        {required: true, trigger: 'blur', validator: checkNewPassword2,},
                    ],
                }
            }
        },
        mounted() {

        },
        methods: {
            submitPwd() {
                this.loading = true
                this.$refs['password'].validate((valid) => {
                    if (valid) {
                        axiosUtils.get('/updateQWE', this.password).then(res => {
                            this.loading = false
                        }, () => {
                            this.loading = false
                        })
                    } else {
                        this.loading = false
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>